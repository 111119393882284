import * as React from "react";

import {
  lineNumber,
  partDescriptionMetadata,
  partNumberMetadata,
  partWeightMetadata,
  quantitySF,
} from "src/components/PartsCatalogueList/PartsMetadata";

import { IPartModel, IPurchase } from "online-services-types";
import { IResponsiveTableColumn } from "../ResponsiveTable/interfaces";
import { ResponsiveTable } from "../ResponsiveTable";

interface IResponsiveTreeProps {
  purchase: IPurchase;
  items: IPartModel[];
  columns: IResponsiveTableColumn[];
}

const REGULAR_ITEM_PARENT = "000000";

export const colmsWithoutPrice = (parentType: string) => [
  lineNumber,
  partDescriptionMetadata(parentType),
  partNumberMetadata,
  partWeightMetadata(parentType),
  quantitySF,
];

export class ResponsiveTreeSF extends React.Component<IResponsiveTreeProps> {
  public render() {
    const { purchase, items, columns } = this.props;
    const regularLineItems = items.filter((lineItem) => lineItem.parentRow === REGULAR_ITEM_PARENT);
    const bomElements = items.filter((lineItem) => lineItem.parentRow !== REGULAR_ITEM_PARENT);

    const isSublistNeeded = (item: IPartModel) => {
      if (item !== undefined && item.isBomHeader) {
        return renderSubList();
      }
      return null;
    };

    const renderSubList = () => {
      return (
        <ResponsiveTable<IPartModel>
          columns={colmsWithoutPrice(purchase.type)}
          rows={bomElements}
          isSubList={true}
          keyExtractor={(row, idx) => `${row.id}-${idx}`}
        />
      );
    };

    return (
      <ResponsiveTable<IPartModel>
        columns={columns}
        rows={regularLineItems}
        sublistRenderer={isSublistNeeded}
        keyExtractor={(row, idx) => `${row.id}-${idx}`}
      />
    );
  }
}
